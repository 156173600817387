import './App.css';
import Logo from "./img/logo.png";
import Modal from './Modal';
import TabIcon from "./img/tabicon.png";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaFacebookF,FaInstagram,FaLinkedinIn } from "react-icons/fa";


const userLang = navigator.language || navigator.userLanguage;


function App() {

    const handleSubmit = async (event) => {
        event.preventDefault()


        const data = new FormData(event.currentTarget);
        const values = Object.fromEntries(data.entries());

        const message = document.getElementById('response-text')

        const response = await fetch("api/signup", {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(values)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('error happened')
                } else {
                    setShow(true)
                }
            })
            .catch(error => {
                message.innerHTML = "An error occurred, please try again or send an email to kontakt@goodtalks.dk"
                message.style.borderColor = "#ff0000"
                message.style.backgroundColor = "#ff0000"
            })

        message.style.display = "block"
    }

    const [show, setShow] = useState(false);


    const getCompanyID = () => {
        return new URLSearchParams(window.location.search).get('company-id')
    }

    const alertmes = () => {
        window.location.href = "https://goodtalks.dk"
    }

    const showCompanyIDField = () => {
        return getCompanyID() != null ? "none" : "inherit"
    }
    const [articletitle, setArticleTitle] = useState("");
    const [articletext, setArticleText] = useState("");
    const [title, setTitle] = useState("");
    const [fname, setFname] = useState("");
    const [placeholderfname, setFnamePlaceholder] = useState("")
    const [lname, setLname] = useState("");
    const [placeholderlname, setLnamePlaceholder] = useState("")
    const [consent, setConsent] = useState("");
    const [and, setAnd] = useState("");
    const [applies, setApplies] = useState("");
    const [modal, setModal] = useState("");
    const [modaltext, setModalText] = useState("");
    const [submitText, setSubmit] = useState("");


    useEffect(() => {
        if (userLang == 'da-dk') {
            changeLangDk();
        } else {
            changeLangEn();
        }
    }, [])


    const changeLangEn = () => {
        setArticleTitle("Sign-up to Goodtalks and get access to networking and inspiration")
        setArticleText("Please fill out your credentials as the first step in creating your Goodtalks account.")
        setTitle("CREATE AN ACCOUNT")
        setFname("First Name");
        setFnamePlaceholder("First Name");
        setLname("Last Name");
        setLnamePlaceholder("Last Name");
        setConsent("I give consent to GoodTalks to use this information to build a profile when my request is approved and to regulate access to the platform. ")
        setAnd("&")
        setApplies("applies")
        setModal("Thank you for signing up")
        setModalText("Next, you’ll receive an email with an activation link to finalise your profile")
        setSubmit("Submit")

    }

    const changeLangDk = () => {
        setArticleTitle("Velkommen til Goodtalks")
        setArticleText("Kom i gang med dit medlemskab ved at udfylde felterne")
        setTitle("OPRET BRUGER")
        setFname("Fornavn");
        setFnamePlaceholder("Fornavn");
        setLname("Efternavn");
        setLnamePlaceholder("Efternavn");
        setConsent("Jeg giver hermed samtykke til, at GoodTalks kan anvende mine personlige oplysninger til at skabe en profil.*")
        setAnd("&")
        setModal("Tak for din registrering ")
        setModalText("Der er en aktiveringsmail på vej til din indbakke. Klik på linket for at udfylde resten af din profil, som giver dig adgang til alt det spændende i Goodtalks universet.")
        setSubmit("Skab profil")

    }



    return (
        <div className="App">
            <link rel="tab icon" href={TabIcon}></link>
            <nav className="top-navigation">
                <a href="goodtalks.dk">
                    <img className="logo" alt="logo" src={Logo} />
                </a>
            </nav>

    
            <p id='response-text'></p>
            <div className='container'>
                <section>

                    <article>
                        <div className="form" >
                            <h1>{articletitle}</h1>
                            <h4>{articletext}</h4>

                        </div>
                    </article>
                    <aside>
                        <div className="form" >
                            <form onSubmit={handleSubmit}>
                                <h1 className="title">{title}</h1>
                                
                                    <div className="field name">
                                        <label htmlFor="firstName" id="fname">{fname}</label>
                                        <input type="text" id="firstName" name="firstname" placeholder={placeholderfname} required />
                                    </div>
                                    <div className="field name">
                                        <label htmlFor="lastName" id="lname">{lname}</label>
                                        <input type="text" id="lastName" name="lastname" placeholder={placeholderlname} required />
                                    </div>
                                

                                <div className="field">
                                    <label htmlFor="email" id="email">E-mail</label>
                                    <input type="email" id="email" name="email" placeholder="E-mail" required />
                                </div>

                                <div className="field" style={{ display: showCompanyIDField() }} >
                                    <label htmlFor="companyId" className="companyid">Company ID</label>
                                    <input
                                        type="text"
                                        id="companyId"
                                        name="company_id"
                                        placeholder="xxx"
                                        defaultValue={getCompanyID()}
                                        required />
                                </div>
                                <div className="accept-container">
                                    <form className='checkbox'>
                                        <input type="checkbox" id="marketing-consent" name="user-consent" value="yes" required />
                                        <label htmlFor="marketing-consent" id="consent">
                                            {consent}
                                        </label>
                                    </form>
                                </div>
                                <br>
                                </br>
                                <div className='button-container'>
                                    <div className="center">
                                        <input id="submit-button" type="submit" value={submitText}/>
                                    </div>
                                </div>

                                <span>
                                    <p><a href="https://d1c2gz5q23tkk0.cloudfront.net/assets/uploads/3362385/asset/Goodtalks_Terms_and_Conditions_2022.pdf?1658306442">Terms of use </a> {and} <a href="https://d1c2gz5q23tkk0.cloudfront.net/assets/uploads/3268015/asset/Goodtalks_Privacy_Policy_2022.pdf?1647446934">Privacy Policy</a> {applies}</p>
                                </span>


                            </form>
                        </div>
                    </aside>
                </section>
                <Modal title="" onClose={() => alertmes()} show={show}>
                    <h2>{modal}</h2>
                    <p>{modaltext}</p>
                </Modal>
            </div>
            <footer>
                <div class="footer-container">
                    <div class="footer-row">
                        <div class="footer-col">
                            <h4>Contact</h4>
                            <ul>
                                <li><a href="#">Tingskiftevej 5</a></li>
                                <li><a href="#">2900 Hellerup</a></li>
                                <li><a href="mailto:kontakt@goodtalks.dk">kontakt@goodtalks.dk</a></li>
                                <li><a href="#">+45 30 76 33 00</a></li>
                                <li><a href="#"></a></li>
                            </ul>
                        </div>
                        <div class="footer-col">
                            <h4>Terms of Use</h4>
                            <ul>
                                <li><a href="https://d1c2gz5q23tkk0.cloudfront.net/assets/uploads/3362385/asset/Goodtalks_Terms_and_Conditions_2022.pdf?1658306442">Terms and Conditions</a></li>
                                <li><a href="https://d1c2gz5q23tkk0.cloudfront.net/assets/uploads/3268015/asset/Goodtalks_Privacy_Policy_2022.pdf?1647446934">Privacy Policy</a></li>
                            </ul>
                        </div>
                        <div class="footer-col">
                            <h4>Social</h4>
                            <div class="social-links"> 
                                <a href="https://www.facebook.com/goodtalksdk/"><FaFacebookF/>  </a>
                                <a href="https://www.instagram.com/goodtalks.dk/"><FaInstagram/></a>
                                <a href="https://www.linkedin.com/company/goodtalks/?viewAsMember=true"><FaLinkedinIn/></a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </footer>
        </div>
        
    );
}

export default App;
